<template>
  <div class="article-box">
    <el-table :data="machineData" style="width: 100%" v-loading="loading">
      <!-- <el-table-column prop="serial" label="序号" type="index" align="center"></el-table-column> -->
      <el-table-column prop="ID" label="设备ID" align="center"></el-table-column>
      <el-table-column prop="MechineName" label="设备名称" align="center"></el-table-column>
      <el-table-column prop="MechineCode" label="设备编码" align="center" width="150"></el-table-column>
      <el-table-column prop="MachineType" label="设备类型" align="center"></el-table-column>
      <el-table-column prop="RegTime" label="注册时间" align="center" width="150"></el-table-column>
      <el-table-column prop="InsertTime" label="绑定时间	" align="center" width="150"></el-table-column>
      <el-table-column prop="LaunchTime" label="投放时间	" align="center" width="150"></el-table-column>
      <el-table-column prop="AgentName" label="代理名称" align="center"></el-table-column>
      <el-table-column prop="SchoolName" label="学校名称	" align="center"></el-table-column>
      <el-table-column label="城市" align="center" width="200">
        <template slot-scope="scope">
          <span>{{scope.row.provinceName + scope.row.cityName + scope.row.areaName}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="provinceName" label="省" align="center"></el-table-column>
      <el-table-column prop="cityName" label="市" align="center"></el-table-column>
      <el-table-column prop="areaName" label="区" align="center"></el-table-column> -->
    </el-table>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
        layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    machineList
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        machineData: [], // 设备列表
        loading: false, //加载框
        currpage: 1, // 初始页
        pagesize: 10, // 每页显示条目个数
        CountNum: 0 // 总条数
      }
    },
    mounted() {
      this.machineSerchList(1) // 获取第一页设备列表
    },
    methods: {
      machineSerchList(currpage) { // 设备列表
        this.loading = true
        const data = {
          page: currpage, // 当前页数
          limit: this.pagesize // 每页条数
        }
        machineList(data).then(res => {
          this.machineData = res.data.List // 设备列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        this.machineSerchList(val)
        this.currpage = val
      }
    }
  }
</script>

<style>
</style>
